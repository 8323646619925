@import "./src/styles/_foundation.scss";
.basic-button {
  padding: spacing(6) spacing(9) spacing(6);

  display: inline-block;
  font-weight: bold;
  color: color(black);
  transition: transition(fast);
  font-size: font-size();
  cursor: pointer;
  position: relative;
  background-color: transparent;
  border: none;
  a {
    color: color(black);
  }

  button[disabled] {
    opacity: 0.5;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: spacing(1) solid color(gray, borders);
  }
  &:hover {
    &:before {
      border-width: spacing(2);
    }
  }
  &.basic-button--inverted {
    background-color: color(white);
  }
  &.basic-button--main {
    &:before {
      border-color: color(yellow);
    }
  }
  &.basic-button--small {
    padding: spacing(3) spacing(6) spacing(3);
    font-size: font-size(s);
  }
  &.basic-button--icon {
    padding-left: spacing(2, 15);
  }
  .basic-button__icon {
    width: spacing(8);
    text-align: center;
    display: inline-block;
    position: absolute;
    left: spacing(6);
    top: spacing(5);
    img {
      max-height: spacing(10);
    }
  }
  & + .basic-button {
    margin-left: spacing(6);
  }
}
