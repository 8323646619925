@import "./src/styles/_foundation.scss";
.top-bar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: all 0.01s;
  transition-delay: 0.1s;
  height: spacing(2, 22);

  &.top-bar--scrolled {
    &:not(.top-bar-state-open) {
      top: 0 - spacing(2, 22);
    }
  }
  .top-bar__main-menu {
    background-color: color(gray, light);
    height: spacing(2, 22);
    border-bottom: 1px solid color(gray, borders);
    padding: spacing(6) spacing(4) spacing(4);
    display: flex;
    justify-content: space-between;
    .main-menu__logo {
      padding: 0 spacing(10) 0 spacing(8);
      img {
        max-height: spacing(2, 13);
      }
    }
    .main-menu__menu {
      width: 100%;
      line-height: spacing(2, 14);
    }
    .main-menu__right-menu {
      text-align: right;
      width: 360px;
      line-height: spacing(2, 14);
      padding-right: spacing(2, 5);
    }
  }
  .top-bar__sections {
    background-color: color(white);
    max-height: 0vh;
    transition: transition(slow);
    overflow: hidden;
    padding-bottom: 0;
  }
  &.top-bar-state-open {
    height: 100vh;
    transition-delay: 0s;
    background-color: transparentize(color(black), 0.4);
    .top-bar__sections {
      max-height: calc(100vh - 88px);
      overflow-y: scroll;
      padding-bottom: spacing(4);
    }
  }
  //SMALL SCREENS
  @media only screen and (max-height: breakpoint(desktopHDHeight)) {
    height: spacing(2, 18);
    .top-bar__main-menu {
      height: spacing(2, 18);
      padding: spacing(4) spacing(4) spacing(4);

      .main-menu__logo {
        padding: spacing(2, 2.5) spacing(6) 0 spacing(6);
        img {
          max-height: spacing(2, 8);
        }
      }
    }
    &.top-bar--scrolled {
      &:not(.top-bar-state-open) {
        top: 0 - spacing(2, 18);
      }
    }
  }
  @media only screen and (max-width: breakpoint(desktopSD)) {
    height: spacing(2, 18);
    .top-bar__main-menu {
      height: spacing(2, 18);
      padding: spacing(4) spacing(4) spacing(4);

      .main-menu__logo {
        padding: spacing(2, 2.5) spacing(6) 0 spacing(6);
        img {
          max-height: spacing(2, 8);
        }
      }
    }
    &.top-bar--scrolled {
      &:not(.top-bar-state-open) {
        top: 0 - spacing(2, 18);
      }
    }
  }
  @media only screen and (max-width: breakpoint(tablet)) {
    display: none;
  }
}
