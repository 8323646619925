@import "./src/styles/_foundation.scss";
.vertical-buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: font-size();
  line-height: line-height();
  font-family: font-family(display);
  li {
    &.active {
      color: color(yellow);
      &:hover {
        color: color(yellow);
        cursor: default;
      }
    }
    &:hover {
      color: color(black);
      cursor: pointer;
    }
  }
}
