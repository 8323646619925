@import "./src/styles/_foundation.scss";
.block-button {
  margin-bottom: spacing(10);
  padding-bottom: spacing(4);
  border-bottom: 4px solid transparent;
  h3 {
    border-bottom: 1px solid color(gray, borders);
    padding-bottom: spacing(8);
    margin-bottom: spacing(6);
  }
  p {
    color: color(gray);
  }

  .block-button__image {
    img {
      width: 100%;
      max-height: 270px;
      object-fit: cover;
    }
  }

  &.block-button--link {
    display: inline-block;
    &:hover {
      border-bottom: 4px solid color(yellow);
    }
  }
  &.block-button--small {
    h3 {
      padding-bottom: spacing(2);
      margin-bottom: spacing(4);
      padding-top: 0;
      margin-top: spacing(2);
    }
  }
}
