@import "./src/styles/_foundation.scss";
.top-bar-mobile {
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: all 0.01s;
  transition-delay: 0.1s;
  height: spacing(2, 22);

  &.top-bar--scrolled {
    &:not(.top-bar-state-open) {
      top: 0 - spacing(2, 22);
    }
  }
  .top-bar__main-menu {
    background-color: color(gray, light);
    height: spacing(2, 22);
    border-bottom: 1px solid color(gray, borders);
    padding: spacing(6) spacing(4) spacing(4);
    display: flex;
    justify-content: space-between;

    .main-menu__logo {
      padding: 0 spacing(10) 0 spacing(8);
      vertical-align: top;
      img {
        max-height: spacing(2, 13);
      }
    }

    .main-menu__right-menu {
      text-align: right;
      line-height: spacing(2, 14);
      padding-right: spacing(2, 5);

      .more-button {
        padding-left: spacing(2, 5);
      }
    }
  }
  .top-bar__sections {
    background-color: color(white);
    max-height: 0vh;
    transition: transition(slow);
    overflow: hidden;
    padding-bottom: 0;
  }
  &.top-bar-state-open {
    height: 100vh;
    transition-delay: 0s;
    background-color: transparentize(color(black), 0.4);
    .top-bar__sections {
      max-height: calc(100vh - 88px);
      overflow-y: scroll;
      padding-bottom: spacing(4);
    }
    .top-bar__sections.top-bar__sections-more {
      display: none;
    }
  }
  &.top-bar-state-more-open {
    height: 100vh;
    transition-delay: 0s;
    background-color: transparentize(color(black), 0.4);
    .top-bar__sections.top-bar__sections-more {
      max-height: calc(100vh - 88px);
      overflow-y: scroll;
    }
  }
  .more-menu__item {
    padding: spacing(2, 4) spacing(2, 2);
    border-bottom: 1px solid color(gray, borders);
    font-size: font-size();
    line-height: line-height();
  }

  @media only screen and (max-width: breakpoint(tablet)) {
    display: block;
  }

  @media only screen and (max-width: breakpoint(mobile)) {
    .top-bar__main-menu {
      padding: spacing(3) spacing(2) spacing(2);
      flex-direction: column;
      height: spacing(2, 28);
      justify-content: flex-start;
      .main-menu__logo {
        padding: 0 spacing(10) 0 spacing(4);
        vertical-align: top;
        img {
          max-height: spacing(2, 10);
        }
      }
      .main-menu__right-menu {
        text-align: left;
        padding-left: spacing(2, 2);
        padding-right: spacing(2, 2);
      }
    }
  }
  @media only screen and (max-width: breakpoint(mobileSD)) {
    .main-menu__right-menu {
      text-align: left;
      padding-left: spacing(2, 2);
      padding-right: 0;
      .more-button {
        padding-left: spacing(2, 2) !important;
      }
    }
  }
}
