@import "./src/styles/_foundation.scss";
.menu-button {
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  font-size: font-size(b);
  position: relative;
  a {
    color: color(gray);
    &:hover {
      color: color(black);
    }
  }
  .menu-button__arrow {
    position: absolute;
    right: 0;
    top: spacing(6);
    &:before {
      content: "";
      width: 0;
      height: 0;
      right: 0;
      top: spacing(4);
      border-style: solid;
      border-width: 8px 6px 0 6px;
      display: block;
      position: absolute;
      border-color: color(gray) transparent transparent transparent;
    }
  }
  &.menu-button--arrow {
    padding-right: spacing(2, 5);
    & + .menu-button {
      margin-left: spacing(2, 4);
    }
  }
  &:hover {
    color: color(black);
  }
  & + .menu-button {
    margin-left: spacing(2, 8);
  }

  &.menu-button--active {
    color: color(black);
    .menu-button__arrow {
      &:before {
        transform: rotate(180deg);
        border-color: color(yellow) transparent transparent transparent;
      }
    }
  }
  //SMALL SCREENS
  @media only screen and (max-height: breakpoint(desktopHDHeight)) {
    font-size: font-size();
    & + .menu-button {
      margin-left: spacing(2, 4);
    }
  }
  @media only screen and (max-width: breakpoint(desktopSD)) {
    font-size: font-size();
    & + .menu-button {
      margin-left: spacing(2, 4);
    }
  }
  @media only screen and (max-width: breakpoint(mobile)) {
    font-size: font-size(s);
  }
  @media only screen and (max-width: breakpoint(mobileSD)) {
    & + .menu-button {
      margin-left: spacing(2, 2);
    }
    &.menu-button--arrow {
      padding-right: spacing(2, 4);
      & + .menu-button {
        margin-left: spacing(2);
      }
    }
  }
}
