@import "./src/styles/_foundation.scss";
.promotion-card {
  border: 2px solid color(gray, borders);
  margin: 2.5vw 0;
  height: 500px;
  vertical-align: top;
  position: relative;

  &.promotion-card--web {
    .promotion-card__content {
      height: 470px;
    }
    .promotion-card__image {
      img,
      &:before {
        height: 500px;
      }
    }
  }
  .promotion-card__content {
    z-index: 15;
    position: absolute;
    padding: spacing(8);
    top: 0;
    left: 0;
    height: 280px;
    .promotion-card__name {
      font-family: font-family(display);
      text-transform: uppercase;
      color: color(white);
      font-weight: bold;
    }
    .promotion-card__discount {
      font-family: font-family(display);

      color: color(yellow);
      font-weight: bold;
    }
    .promotion-card__actions {
      position: absolute;
      padding: 0 spacing(8);
      bottom: 0;
      left: 0;
    }
  }
  .promotion-card__image {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      z-index: 11;
      width: 100%;
      height: 400px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 5%,
        rgba(0, 0, 0, 0.2) 35%,
        rgba(0, 0, 0, 0.2) 65%,
        rgba(0, 0, 0, 0.8) 95%
      );
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }
  }
  .promotion-card__info {
    z-index: 20;
    position: absolute;
    padding: spacing(6) spacing(8);
    bottom: 0;
    background-color: color(white);
    left: 0;
    p {
      font-size: font-size(s);
      line-height: line-height(s);
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
