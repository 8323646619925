@import "./src/styles/_foundation.scss";
.slider {
  min-height: 760px;
  background-color: color(gray);
  position: relative;
  overflow: hidden;

  &.slider--no-link {
    .slider-link {
      display: none;
    }
  }
  &.slider--no-controls {
    .slider__controls {
      display: none;
    }
  }
  &:hover {
    .slider__controls {
      .controls__arrows {
        .controls__arrow__left,
        .controls__arrow__right {
          opacity: 0.5;
        }
      }
    }
  }
  .slider__title {
    padding-top: 2.5vw;
    position: relative;
    z-index: 20;
    h1 {
      color: color(white);
      max-width: 50%;
    }
  }
  .slider__controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    .controls__bottom-bar {
      height: spacing(2, 20);
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: transparentize(color(black), 0.5);
      line-height: spacing(2, 20);
      padding: 0 5vw;
      display: flex;
      justify-content: space-between;
      .slider-link {
        a {
          color: color(white);
          font-family: font-family(display);
          &:hover {
            color: color(yellow);
          }
          img {
            display: inline-block;
            height: spacing(2, 12);
            position: relative;
            top: spacing(6);
          }
        }
      }
      .slider-buttons {
        padding-top: spacing(1);
        .slider-button {
          cursor: pointer;
          width: spacing(7);
          height: spacing(7);
          border-radius: spacing(6);
          border: 2px solid transparent;
          background-color: color(gray, borders);
          display: inline-block;
          margin-right: spacing(6);
          transition: transition();
          &.slider-button--active {
            border: 2px solid color(gray, borders);
            background-color: transparent;
          }
        }
      }
    }
    .controls__arrows {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: calc(100% - 80px);
      .controls__arrow__left {
        position: absolute;
        left: 0;
        top: 50%;
        height: spacing(2, 40);
        width: spacing(2, 22);
        transform: translateY(-50%);
        background-color: transparentize(color(black), 0.75);
        opacity: 0;
        transition: transition();
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        text-align: center;
        line-height: spacing(2, 55);
        img {
          display: inline-block;
          transform: scale(-1);
        }
      }
      .controls__arrow__right {
        position: absolute;
        right: 0;
        top: 50%;
        height: spacing(2, 40);
        width: spacing(2, 22);
        transform: translateY(-50%);
        background-color: transparentize(color(black), 0.75);
        opacity: 0;
        transition: transition();
        cursor: pointer;
        text-align: center;
        line-height: spacing(2, 55);
        img {
          display: inline-block;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .slider__images {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    white-space: nowrap;

    .slider__image {
      display: inline-block;
      width: 100%;
      height: 760px;
      img {
        width: 100%;
        height: 760px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  //Small screens
  @media only screen and (max-width: breakpoint(desktopSD)) {
    min-height: 520px;
    .slider__images {
      .slider__image {
        height: 520px;
        img {
          height: 520px;
        }
      }
    }
  }
  //Small screens
  @media only screen and (max-height: 768px) {
    min-height: 520px;
    .slider__images {
      .slider__image {
        height: 520px;
        img {
          height: 520px;
        }
      }
    }
  }
}
