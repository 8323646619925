@import "./src/styles/_foundation.scss";
.unit-card {
  padding-bottom: spacing(10);
  border-bottom: 1px solid color(gray, borders);
  margin-bottom: spacing(10);
  display: flex;
  .unit-card__info {
    width: 100%;
    .unit-card__title {
      font-size: font-size();
      color: color(black);
    }
    .unit-card__price {
      font-size: font-size(b);
      color: color(black);
      font-weight: bold;
    }
    .unit-card__details {
      font-size: font-size(s);
      line-height: line-height(s);
    }
  }
  .unit-card__actions {
    text-align: right;
    .basic-button {
      margin-bottom: spacing(4);
    }
  }
}
