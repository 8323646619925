@import "./src/styles/_foundation.scss";
.table {
  &.table--small {
    font-size: font-size(s);
    line-height: line-height(s);
    thead,
    tfoot {
      font-size: font-size(s);
      line-height: line-height(s);
    }
    td {
      padding-top: spacing(2);
      padding-bottom: spacing(2);
    }
    th {
      padding: spacing(2) spacing(6) spacing(2) spacing(6);
    }
  }
  td,
  th {
    padding: spacing(4) spacing(5) spacing(4) spacing(5);
    &.td-right {
      text-align: right;
    }
  }
  thead {
    font-size: font-size();
    text-align: left;
    color: color(black);
    th {
    }
  }
  tfoot {
    font-size: font-size(b);
    text-align: left;
    color: color(black);

    th {
      padding-top: spacing(8);
    }
  }
  td {
    padding-top: spacing(4);

    &:last-child {
      border-right: none;
    }
    &.td-one-line {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
  tbody {
    td {
      border-bottom: 1px solid color(gray, borders);
    }
  }

  &.characteristics-table {
    border-collapse: collapse;
    max-width: 100%;
    font-size: font-size();
    thead {
      color: color(white);
      th {
        border-bottom: 1px solid color(white);
        border-right: 1px solid color(white);
        padding: 0;
        .characteristics-model-image {
          background-color: color(white);
          padding: spacing(10);
          img {
            width: 300px;
          }
        }
        .characteristics-model-name {
          background-color: color(gray, medium);
          padding: spacing(4) spacing(6) spacing(4) spacing(6);
        }
      }
    }

    tbody {
      td {
        font-size: font-size(s);
        vertical-align: top;

        min-width: 280px;
        max-width: 400px;
        &:first-child {
          min-width: 280px;
        }
      }
      tr:nth-child(odd) {
        background-color: color(gray, light);
      }
      .characteristics-group {
        background-color: color(gray, medium) !important;
        color: color(white);
        font-family: font-family(display);
        font-weight: bold;
        text-transform: uppercase;
        font-size: font-size(s);
      }
      strong {
        color: color(black);
      }
    }
    @media only screen and (max-width: breakpoint(mobile)) {
      thead {
        color: color(white);
        th {
          .characteristics-model-image {
            img {
              max-width: 240px;
            }
          }
        }
      }
      tbody {
        td {
          font-size: font-size(s);
          vertical-align: top;

          min-width: 200px;
          max-width: 240px;
          &:first-child {
            min-width: 200px;
            max-width: 240px;
          }
        }
      }
    }
  }
  &.services-table {
    font-size: font-size(s);
    line-height: line-height(s);
    th {
      border-bottom: 1px solid color(gray, borders);
    }
    td {
      vertical-align: top;
    }
  }

  &.buy-table {
    width: 100%;
    font-size: font-size(s);
    line-height: line-height(s);
    th {
      border-bottom: 1px solid color(gray, borders);
    }
    td {
      vertical-align: top;
    }
    .price {
      text-align: right;
    }
  }
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: spacing(6);
}
