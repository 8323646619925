@import "./src/styles/_foundation.scss";
//Paragraphs

p {
  font-size: font-size();
  line-height: line-height();
  margin-top: 0;
  color: color(gray, dark);
  margin-bottom: calc(line-height() / 2);
  padding-top: 0;
  padding-bottom: 0;

  //Mobile
  @media only screen and (max-width: breakpoint(desktopHD)) {
    font-size: font-size(s);
    line-height: line-height(s);
  }
  span {
    &.p-s {
      font-size: font-size(s);
      line-height: line-height(s);
      font-family: font-family(display);
      color: color(gray, medium);
    }
    &.p-smalltext {
      color: color(gray, medium);
    }
  }
  &.p-s {
    font-size: font-size(s);
    line-height: line-height(s);
    font-family: font-family(display);
    color: color(gray, medium);
  }
  &.p-smalltext {
    color: color(gray, medium);
  }

  &.p-b {
    font-size: font-size(b);
    line-height: line-height(b);
    margin-bottom: line-height(b);
    font-weight: 400;

    //Mobile
    @media only screen and (max-width: breakpoint(desktopHD)) {
      font-size: font-size();
      line-height: line-height();
    }
  }

  &.p-fluid {
    max-width: 100% !important;
  }
}

//Tablet
@media only screen and (max-width: breakpoint(tablet)) {
}
//Mobile
@media only screen and (max-width: breakpoint(mobile)) {
}
