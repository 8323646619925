@import "./src/styles/_foundation.scss";
.footer {
  background-color: color(gray, light);
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: font-size();
    li {
      margin-bottom: spacing(4);
      a {
        font-weight: bold;
      }
    }
  }
  .footer__legal {
    border-top: 1px solid color(gray, borders);
    font-size: font-size(s);
  }
  .footer__fnr {
    text-align: right;
    padding-top: spacing(2, 15);
    img {
    }
  }
}
