@import "./src/styles/_foundation.scss";
.model-bar {
  width: 100%;
  height: spacing(2, 22);
  background-color: color(gray, light);
  .model-bar__model-name h2 {
    line-height: spacing(2, 22);
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .model-bar__menu {
    text-align: right;
    .menu__link {
      line-height: spacing(2, 22);
      height: spacing(2, 22);
      margin: 0 spacing(6);
      font-weight: bold;
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: spacing(2);
        background-color: color(yellow);
        transition: transition(fast);
        transform: scaleX(0);
      }
      &:hover {
        &:before {
          transform: scaleX(1);
        }
      }
      &.menu__link--active {
        &:before {
          transform: scaleX(1);
        }
      }
    }
    .menu__button {
      margin: 0 spacing(6);
      padding: spacing(7) spacing(9) spacing(6);
      border: spacing(1) solid color(yellow);
      display: inline-block;
      font-weight: bold;
      color: color(black);
      transition: transition(fast);
      &:hover {
        background-color: color(yellow);
      }
    }
  }

  @media only screen and (max-width: breakpoint(mobile)) {
    .model-bar__model-name h2 {
      line-height: spacing(2, 12);
    }
    height: spacing(2, 24);
    .col {
      margin-bottom: 0;
    }
    .model-bar__menu {
      text-align: left;
      .menu__link {
        line-height: spacing(2, 10);
        height: spacing(2, 10);
        margin: 0 spacing(4);
        font-size: font-size();
      }
      .menu__button {
        display: none;
      }
    }
  }
}
