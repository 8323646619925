@import "./src/styles/_foundation.scss";
.checkbox {
  user-select: none;
  cursor: pointer;
  position: relative;
  padding-left: spacing(11);
  margin: spacing(6) 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox__image {
    border: 1px solid color(gray, borders);
    padding: spacing(2);
    border-radius: spacing(2);
    margin-right: spacing(3);
    img {
      height: spacing(7);
      vertical-align: middle;
      object-fit: contain;
      width: spacing(12);
      object-position: top;
    }
  }

  .checkbox__checkmark {
    position: absolute;
    top: spacing(1);
    left: 0;
    height: spacing(8);
    width: spacing(8);
    background-color: color(white);
    border: 1px solid color(black);
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkbox__checkmark {
    border-color: color(yellow);
  }
  &:hover {
    color: color(yellow);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkbox__checkmark {
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkbox__checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox__checkmark:after {
    left: 8px;
    top: 2px;
    width: spacing(3);
    height: spacing(6);
    border-top: 1px solid color(black);
    border-left: 1px solid color(black);
    transform: rotate(225deg);
  }

  @media only screen and (max-width: breakpoint(mobile)) {
    font-size: font-size(s);

    .checkbox__checkmark {
      top: -4px !important;
    }
  }
}
