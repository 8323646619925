@import "./src/styles/_foundation.scss";
.BenefitsTable {
  table {
    margin-bottom: spacing(2, 10);
  }
  .seller {
    h6 {
      padding: 0;
      margin: 0;
    }
    h2 {
      padding: 0;
      margin: 0 0 spacing(2, 4);
    }
  }
  .expiration {
    h6 {
      padding: 0;
      margin: 0;
    }
    h2 {
      padding: 0;
      margin: 0 0 spacing(2, 4);
    }
  }
  .coupon-info {
    margin-top: spacing(2, 10);
    width: 100%;
    .col {
      padding: 0 0;
    }
  }
  .TimeLeft {
    padding: spacing(2, 3) spacing(2, 4);
    background-color: color(yellow);
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(white);
  }
}
