@import "./src/styles/_foundation.scss";
.plan-card {
  border: 2px solid color(gray, borders);
  margin: 2.5vw 0;
  height: 500px;
  vertical-align: top;
  position: relative;

  &.plan-card--web {
    .plan-card__content {
      height: 470px;
    }
    .plan-card__image {
      img,
      &:before {
        height: 500px;
      }
    }
  }
  .plan-card__content {
    z-index: 15;
    position: absolute;
    padding: spacing(8);
    top: 0;
    left: 0;
    height: 320px;
    .plan-card__name {
      font-family: font-family(display);
      text-transform: uppercase;
      color: color(yellow);
      font-weight: bold;
    }

    .plan-card__months,
    .plan-card__transctions,
    .plan-card__installments {
      color: color(white);
      font-size: font-size();
      line-height: line-height();
    }
    .plan-card__actions {
      position: absolute;
      padding: 0 spacing(8);
      bottom: 0;
      left: 0;
    }
  }
  .plan-card__image {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      z-index: 11;
      width: 100%;
      height: 400px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 5%,
        rgba(0, 0, 0, 0.2) 35%,
        rgba(0, 0, 0, 0.2) 65%,
        rgba(0, 0, 0, 0.8) 95%
      );
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }
  }
  .plan-card__info {
    z-index: 20;
    position: absolute;
    padding: spacing(6) spacing(8);
    bottom: 0;
    background-color: color(white);
    left: 0;
    font-size: font-size(s);
    line-height: line-height(s);
    height: 150px;
    width: 100%;
    overflow-y: scroll;
    h4 {
      color: color(black);
    }
    p {
      font-size: font-size(s);
      line-height: line-height(s);
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
