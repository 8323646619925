@import "./src/styles/_foundation.scss";
.icon-button {
  text-align: center;
  font-size: font-size(s);
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    color: color(yellow);
  }
  .icon-button__icon {
    width: spacing(10);
    text-align: center;
    display: inline-block;
    margin-bottom: spacing(4);
    img {
      width: 100%;
    }
  }
}
