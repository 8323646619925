//GRID
@import "./src/styles/_foundation.scss";
//container responsive widths
$containerDesktopHD: 1152px;
$containerDesktop: 1152px;
$containerTablet: 100%;
$containerMobile: 100%;

// This is mixin which you will include in parent
// of columns. It kills default margin of inline-block
// elements.
.grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  & > div {
    vertical-align: middle;
  }

  .col {
    padding: 0 2.5vw;
  }

  .col1 {
    grid-column-end: span 1;
  }
  .col4 {
    grid-column-end: span 4;
  }
  .col5 {
    grid-column-end: span 5;
  }
  .col6 {
    grid-column-end: span 6;
  }
  .col8 {
    grid-column-end: span 8;
  }
  .col9 {
    grid-column-end: span 9;
  }
  .col10 {
    grid-column-end: span 10;
  }
  .col12 {
    grid-column-end: span 12;
  }
  .col13 {
    grid-column-end: span 13;
  }
  .col14 {
    grid-column-end: span 14;
  }
  .col15 {
    grid-column-end: span 15;
  }
  .col16 {
    grid-column-end: span 16;
  }
  .col17 {
    grid-column-end: span 17;
  }
  .col18 {
    grid-column-end: span 18;
  }
  .col20 {
    grid-column-end: span 20;
  }
  .col22 {
    grid-column-end: span 22;
  }
  .col23 {
    grid-column-end: span 23;
  }
  .col24 {
    grid-column-end: span 24;
  }
  .s1 {
    grid-column-start: 1;
  }
  .s2 {
    grid-column-start: 2;
  }
  .s3 {
    grid-column-start: 3;
  }
  .s4 {
    grid-column-start: 4;
  }
  .s5 {
    grid-column-start: 5;
  }
  .s6 {
    grid-column-start: 6;
  }
  .s7 {
    grid-column-start: 7;
  }
  .s8 {
    grid-column-start: 8;
  }
  .s9 {
    grid-column-start: 9;
  }
  .s10 {
    grid-column-start: 10;
  }
  .s11 {
    grid-column-start: 11;
  }
  .s12 {
    grid-column-start: 12;
  }
  .s13 {
    grid-column-start: 13;
  }
  .s14 {
    grid-column-start: 14;
  }
  .s15 {
    grid-column-start: 15;
  }
  .s16 {
    grid-column-start: 16;
  }
  .s17 {
    grid-column-start: 17;
  }
  .s18 {
    grid-column-start: 18;
  }
  .s19 {
    grid-column-start: 19;
  }
  .s20 {
    grid-column-start: 20;
  }
  //Small DESKTOP
  @media only screen and (max-width: breakpoint(desktopSD)) {
    .col-s-1 {
      grid-column-end: span 1;
    }
    .col-s-4 {
      grid-column-end: span 4;
    }
    .col-s-5 {
      grid-column-end: span 5;
    }
    .col-s-6 {
      grid-column-end: span 6;
    }
    .col-s-8 {
      grid-column-end: span 8;
    }
    .col-s-9 {
      grid-column-end: span 9;
    }
    .col-s-10 {
      grid-column-end: span 10;
    }
    .col-s-12 {
      grid-column-end: span 12;
    }
    .col-s-13 {
      grid-column-end: span 13;
    }
    .col-s-14 {
      grid-column-end: span 14;
    }
    .col-s-15 {
      grid-column-end: span 15;
    }
    .col-s-16 {
      grid-column-end: span 16;
    }
    .col-s-17 {
      grid-column-end: span 17;
    }
    .col-s-18 {
      grid-column-end: span 18;
    }
    .col-s-20 {
      grid-column-end: span 20;
    }
    .col-s-22 {
      grid-column-end: span 22;
    }
    .col-s-23 {
      grid-column-end: span 23;
    }
    .col-s-24 {
      grid-column-end: span 24;
    }
    .s-s-1 {
      grid-column-start: 1;
    }
    .s-s-2 {
      grid-column-start: 2;
    }
    .s-s-3 {
      grid-column-start: 3;
    }
    .s-s-4 {
      grid-column-start: 4;
    }
    .s-s-5 {
      grid-column-start: 5;
    }
    .s-s-6 {
      grid-column-start: 6;
    }
    .s-s-7 {
      grid-column-start: 7;
    }
    .s-s-8 {
      grid-column-start: 8;
    }
    .s-s-9 {
      grid-column-start: 9;
    }
    .s-s-10 {
      grid-column-start: 10;
    }
    .s-s-11 {
      grid-column-start: 11;
    }
    .s-s-12 {
      grid-column-start: 12;
    }
    .s-s-13 {
      grid-column-start: 13;
    }
    .s-s-14 {
      grid-column-start: 14;
    }
    .s-s-15 {
      grid-column-start: 15;
    }
    .s-s-16 {
      grid-column-start: 16;
    }
    .s-s-17 {
      grid-column-start: 17;
    }
    .s-s-18 {
      grid-column-start: 18;
    }
    .s-s-19 {
      grid-column-start: 19;
    }
    .s-s-20 {
      grid-column-start: 20;
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .col4,
    .col5,
    .col6,
    .col7,
    .col8,
    .col9,
    .col10,
    .col11,
    .col12 .col13,
    .col14,
    .col15,
    .col16,
    .col17,
    .col18,
    .col19,
    .col20,
    .col21,
    .col22,
    .col23 {
      grid-column-end: span 24;
      margin-bottom: spacing(2, 8);
    }
    .s2,
    .s3,
    .s4,
    .s5,
    .s6,
    .s7,
    .s8,
    .s9,
    .s10,
    .s11,
    .s12,
    .s13,
    .s14,
    .s15,
    .s16,
    .s17 {
      grid-column-start: 3;
    }
    .col24m {
      grid-column-start: 1;
      grid-column-end: span 24;
      & > img {
        width: 100%;
      }
    }
    .col-m-2 {
      grid-column-end: span 12;
    }
    .s-m-13 {
      grid-column-start: 13;
    }
    .s-m-1 {
      grid-column-start: 1;
    }
  }
}
