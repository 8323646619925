@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Louis";
  font-style: normal;
  font-weight: normal;
  src: local("Louis Regular"), url("/fonts/louisregular.woff") format("woff");
}

@font-face {
  font-family: "Louis";
  font-style: italic;
  font-weight: normal;
  src: local("Louis Italic"), url("/fonts/louisitalic.woff") format("woff");
}

@font-face {
  font-family: "Louis";
  font-style: normal;
  font-weight: bold;
  src: local("Louis Bold"), url("/fonts/louisbold.woff") format("woff");
}

@font-face {
  font-family: "Louis";
  font-style: italic;
  font-weight: bold;
  src: local("Louis Bold Italic"),
    url("/fonts/louisbolditalic.woff") format("woff");
}
