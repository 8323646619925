@import "./src/styles/_foundation.scss";
.textfield {
  label {
    display: block;
    text-transform: uppercase;
    font-family: font-family(display);
    font-size: font-size();
    letter-spacing: 0.1em;
    margin-bottom: spacing(2);
  }
  input {
    width: 100%;
    border: 1px solid color(gray, borders);
    font-family: font-family();
    font-size: font-size();
    padding: spacing(6) spacing(6);
    margin-bottom: spacing(2);
    &::placeholder {
      color: color(gray, borders);
    }
  }
  margin-bottom: spacing(8);

  &.textfield--error {
    input {
      border-color: color(red);
    }
  }
  .textfield__error-message {
    font-size: font-size();
    color: color(red);
  }
}
