@import "./src/styles/_foundation.scss";
.car-button {
  margin-bottom: spacing(10);
  padding-bottom: spacing(10);
  border-bottom: 4px solid transparent;
  position: relative;
  display: inline-block;
  text-align: center;

  h3 {
    padding: 0;
    margin: 0;
  }

  .car-button__image {
    img {
      width: 100%;
      height: 160px;
      object-fit: contain;
      object-position: left bottom;
    }
    margin-bottom: spacing(10);
  }
  .car-button__name {
    text-transform: uppercase;
    text-align: left;
  }
  .car-button__price {
    text-align: left;
    h3 {
      color: color(gray, medium);
    }
  }
  .car-button__hover {
    width: 0%;
    height: spacing(2);
    background-color: color(yellow);
    display: inline-block;
    transition: transition();
    transform: translateX(0%);
  }
  .car-button__discount {
    font-size: font-size();
    text-align: left;
    color: color(gray, medium);
  }
  &:hover {
    .car-button__hover {
      width: 100%;
    }
  }
  //Small screens
  @media only screen and (max-width: breakpoint(desktopSD)) {
    .car-button__image {
      img {
        height: 80px;
      }
      margin-bottom: spacing(2);
    }
  }
  //Small screens
  @media only screen and (max-height: breakpoint(desktopHDHeight)) {
    .car-button__image {
      img {
        height: 80px;
      }
      margin-bottom: spacing(2);
    }
    margin-bottom: spacing(4);
    padding-bottom: spacing(2);
  }
  @media only screen and (max-width: breakpoint(tablet)) {
    .car-button__discount {
      font-size: font-size(s);
      line-height: line-height(s);
    }
    .car-button__hover {
      display: none;
    }
  }
}
