@import "./src/styles/_foundation.scss";
.form-row {
  display: block;
  width: 100%;
  .grid {
    .col:first-child {
      padding-left: 0;
    }
  }
  @media only screen and (max-width: breakpoint(mobile)) {
    .grid {
      .col {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
