@import "_foundation.scss";

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  font-family: font-family();
  line-height: line-height();
  font-size: $basicFontSize;
  background-color: color(white);
  color: color(gray);
}
.App {
  padding: spacing(2, 22) 0 0 0;
  //SMALL DESKTOP
  @media only screen and (max-height: breakpoint(desktopHDHeight)) {
    padding: spacing(2, 18) 0 0 0;
  }
  @media only screen and (max-width: breakpoint(desktopSD)) {
    padding: spacing(2, 18) 0 0 0;
  }

  @media only screen and (max-width: breakpoint(tablet)) {
    padding: spacing(2, 22) 0 0 0;
  }
  @media only screen and (max-width: breakpoint(mobile)) {
    padding: spacing(2, 28) 0 0 0;
  }
}

a {
  cursor: pointer;
  color: color(gray);
  text-decoration: none;
  &:hover {
    color: color(black);
    text-decoration: none;
  }
}

hr {
  border-bottom: 1px solid color(gray, borders);
  border-top: 0;
  background-color: color(gray, borders);
  margin: spacing(10) 0;
  color: color(gray, borders);
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: breakpoint(tablet)) {
}
//
@import "globals/_grid.scss";
@import "globals/_headings.scss";
@import "globals/_paragraphs.scss";
@import "globals/_lists.scss";
@import "globals/_tables.scss";
@import "globals/_images.scss";
@import "globals/_sections.scss";
@import "globals/_verticalButtons.scss";
