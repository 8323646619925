@import "./src/styles/_foundation.scss";
.select {
  position: relative;
  label {
    display: block;
    text-transform: uppercase;
    font-family: font-family(display);
    font-size: font-size();
    letter-spacing: 0.1em;
    margin-bottom: spacing(2);
  }
  .select__arrow {
    content: "";
    position: absolute;
    width: spacing(6);
    height: spacing(6);
    border-bottom: 2px solid color(gray);
    border-right: 2px solid color(gray);
    transform: rotate(45deg);
    right: spacing(8);
    top: spacing(13);
  }
  select {
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 1px solid color(gray, borders);
    font-family: font-family();
    font-size: font-size();
    padding: spacing(6) spacing(12) spacing(6) spacing(6);
    margin-bottom: spacing(2);
  }
  margin-bottom: spacing(8);

  &.select--error {
    input {
      border-color: color(red);
    }
  }
  .select__error-message {
    font-size: font-size();
    color: color(red);
  }
}
