@import "./src/styles/_foundation.scss";
//Headings
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: font-family(display);
  font-weight: 300;
  margin-top: 0;
  padding-top: 0;
  &.fluid {
    max-width: 100% !important;
  }
  &.heading-adjust {
    text-indent: -0.5em;
  }
  & > strong {
    font-weight: 500;
  }
  strong {
    font-weight: 500;
  }
}

h1,
.h1 {
  font-size: heading-font-size(h1);
  line-height: heading-line-height(h1);
  margin: heading-line-height(h1) 0 calc(heading-line-height(h1) / 2);
  font-weight: bold;
  color: color(black);
  text-transform: uppercase;
  letter-spacing: 2.299999952316284px;
  &:first-child {
    margin-top: 0;
  }

  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    font-size: heading-font-size(h2);
    line-height: heading-line-height(h2);
    margin: heading-line-height(h2) 0 calc(heading-line-height(h2) / 2);
    letter-spacing: -0.01em;
    max-width: 780px;
  }
}

h2,
.h2 {
  font-size: heading-font-size(h2);
  line-height: heading-line-height(h2);
  margin: heading-line-height(h2) 0 calc(heading-line-height(h2) / 2);
  letter-spacing: 0.9200000166893005px;
  max-width: 780px;
  color: color(black);
  &:first-child {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    font-size: heading-font-size(h3);
    line-height: heading-line-height(h3);
    margin: heading-line-height(h3) 0 calc(heading-line-height(h3) / 2);
    max-width: 720px;
  }
}

h3,
.h3 {
  font-size: heading-font-size(h3);
  line-height: heading-line-height(h3);
  margin: heading-line-height(h3) 0 calc(heading-line-height(h3) / 2);

  color: color(gray, dark);
  &:first-child {
    margin-top: 0;
  }
  & + h1,
  & + h2,
  & + .h1,
  & + .h2 {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    font-size: heading-font-size(h4);
    line-height: heading-line-height(h4);
    margin: heading-line-height(h4) 0 calc(heading-line-height(h4) / 2);
    max-width: 540px;
  }
}

h4,
.h4 {
  font-size: heading-font-size(h4);
  line-height: heading-line-height(h4);
  font-weight: 300;
  margin: heading-line-height(h4) 0 calc(heading-line-height(h4) / 2);
  color: color(gray, medium);
  letter-spacing: 3.450000047683716px;
  text-transform: uppercase;
  &:first-child {
    margin-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
  }
  + h2,
  + .h2,
  + .h1,
  + h1 {
    margin-top: 0;
  }
}

.hanging-punctuation {
  text-indent: -0.3em;
}
.no-wrap {
  word-break: keep-all;
  white-space: nowrap;
}
